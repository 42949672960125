import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    adminsManagersUsers: {
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_ADMINS_USERS_LOADING: (state, data) => {
      state.adminsManagersUsers.loading = data;
    },
    SET_PAGINATION: (state, data) => {
      state.adminsManagersUsers.pagination = data;
    },
  },
  actions: {
    getAdminsManagers: function ({ commit, state }, payload) {
      let url = `admins-managers-list?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.roleId) {
          url = `${url}&roleId=${payload.roleId}`;
        }
        if (payload.username) {
          url = `${url}&username=${payload.username}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_ADMINS_USERS_LOADING", true);
      }
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload && payload.page) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [
                    ...state.adminsManagersUsers.pagination.data,
                    ...res.data.data,
                  ],
                });
              } else if (payload && payload.limit) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_PAGINATION", {
                  total: 0,
                  current: 0,
                  data: res.data.data,
                });
              }
              resolve(res.data.data);
            }
            commit("SET_ADMINS_USERS_LOADING", false);
          })
          .catch((err) => {
            commit("SET_ADMINS_USERS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  (err.data || err.response.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            return resolve();
          });
      });
    },
  },
};
