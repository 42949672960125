import Swal from "sweetalert2";
import router from "../router";

export default {
  namespaced: true,
  state: {
    data: [],
    classification: null,
    clientClassifications: [],
    providerClassifications: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_CLASSIFICATION: (state, classification) => {
      state.classification = classification;
    },
    SET_CLIENT_CLASSIFICATION: (state, classes) => {
      state.clientClassifications = [...classes];
    },
    SET_PROVIDER_CLASSIFICATION: (state, classes) => {
      state.providerClassifications = [...classes];
    },
  },

  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("classifications", data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Class Created Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            router.push({ name: "classes" });
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
            return resolve();
          });
      });
    },

    update: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`classifications/${data.id}/`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Class Updated Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            router.push({ name: "classes" });
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
            return resolve();
          });
      });
    },
    get: function (context, id) {
      let vm = this;
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise(function (resolve) {
        if (typeof id == "undefined") {
          vm.$http
            .get("classifications")
            .then((res) => {
              if (res.status == 200) {
                context.commit("SET_STATE", {
                  key: "data",
                  value: res.data.data,
                });
              }
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve();
            });
        } else {
          vm.$http
            .get(`classifications/${id}`)
            .then((res) => {
              context.commit("SET_STATE", {
                key: "class",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve();
            });
        }
      });
    },

    deleteClass: function (ctx, id) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .delete(`classifications/${id}`)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Class Deleted Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },

    attachClassToUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post(`classifications/${data.id}/attach-user`, { user: data.user })
          .then(() => {
            Swal.fire({
              title: "",
              text: "Class Attached To User Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: err,
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },

    detachClassFromUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post(`classifications/${data.id}/detach-user`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Class Detached From User Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },

    getClientClassifications: async function ({ commit, dispatch }) {
      let res = await dispatch("get");
      if (res) {
        let classes = res.map((item) => {
          if (item.role_id == 3) return { label: item.subject, value: item.id };
        });
        classes = classes.filter((item) => {
          return item !== undefined;
        });
        commit("SET_CLIENT_CLASSIFICATION", classes);
      }
    },
    getProviderClassifications: async function ({ commit, dispatch }) {
      let res = await dispatch("get");
      if (res) {
        let classes = res.map((item) => {
          if (item.role_id == 2) return { label: item.subject, value: item.id };
        });
        classes = classes.filter((item) => {
          return item !== undefined;
        });
        commit("SET_PROVIDER_CLASSIFICATION", classes);
      }
    },
  },
};
