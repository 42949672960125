const state = {
  user: null,
  token: null,
  authenticating: false,
  authenticated: false,
  sendingEmail: false,
  resettingPassword: false,
  secondStepVerificationEnabled: true,
  userInfo: null,
  onlineUsers: [],
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_AUTHENTICATED: (state, bool) => {
    state.authenticated = bool;
  },
  SET_AUTHENTICATING: (state, bool) => {
    state.authenticating = bool;
  },
  SENDING_RESET_EMAIL: (state, bool) => {
    state.sendingEmail = bool;
  },
  RESETTING_PASSWORD: (state, bool) => {
    state.resettingPassword = bool;
  },
  SET_SECOND_VERIFICATION_ENABLED: (state, bool) => {
    state.secondStepVerificationEnabled = bool;
  },
  SET_ONLINE_USERS: (state, users) => {
    state.onlineUsers = users;
  },
};

const actions = {
  login: function ({ commit }, payload) {
    commit("SET_AUTHENTICATING", true);
    let vm = this;
    return new Promise(function (resolve, reject) {
      vm.$http
        .post(`login`, payload)
        .then((tokenRes) => {
          if (tokenRes.status == 200) {
            if (typeof tokenRes.data.access_token != "undefined") {
              //TODO if the 2step is enabled do not set
              //  token here as it will come from the 2nd step veridication API (suggestion) .
              // need to check with backend develoeprs.
              {
                localStorage.setItem("ARSS_TOKEN", tokenRes.data.access_token);
                localStorage.setItem("ARSS_RF", tokenRes.data.refresh_token);
                commit("SET_TOKEN", tokenRes.data.access_token);
              }
              // commit('SET_SECOND_VERIFICATION_ENABLED', true)//TODO change it to be taken from API response
              return resolve(true);
            }
          }
          commit("SET_AUTHENTICATING", false);
          return reject(tokenRes.data);
        })
        .catch((error) => {
          commit("SET_AUTHENTICATING", false);
          return reject(error.response || error);
        });
    });
  },
  setLoggedIn: function (ctx) {
    return new Promise(function (res) {
      if (
        localStorage.getItem("ARSS_TOKEN") &&
        localStorage.getItem("USER_TYPE")
      ) {
        ctx.commit("SET_TOKEN", localStorage.getItem("ARSS_TOKEN"));
        ctx.commit("SET_AUTHENTICATED", true);
        res(true);
      } else {
        ctx.commit("SET_TOKEN", null);
        ctx.commit("SET_AUTHENTICATED", false);
        res(false);
      }
    });
  },
  getUser: function (ctx) {
    return new Promise((resolve, reject) => {
      this.$http
        .get("user")
        .then((res) => {
          if (res.status == 200) {
            ctx.commit("SET_USER", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              ((error.response.data || {}).error || {}).message ==
                "Unauthenticated." ||
              ((error.response.data || {}).error || {}).message ==
                "token expired"
            ) {
              if (window.location.path != "/login") {
                localStorage.removeItem("ARSS_TOKEN");
                localStorage.removeItem("USER_TYPE");
                localStorage.removeItem("C3_LOGIN");
                window.location.href = "/login";
              }
              reject(error);
            }
            reject(error);
          }
        });
    });
  },

  getUserInfo: function (ctx) {
    let vm = this;
    return new Promise(function (resolve) {
      vm.$http.get("user/info").then((res) => {
        if (res.status == 200) {
          ctx.commit("SET_USER_INFO", res.data.data);
          resolve(res.data.data);
        }
      });
    });
  },
  logout: function ({ commit }) {
    return new Promise(function (resolve) {
      localStorage.removeItem("ARSS_TOKEN");
      localStorage.removeItem("ARSS_RF");
      localStorage.removeItem("USER_TYPE");
      localStorage.removeItem("C3_LOGIN");
      commit("SET_AUTHENTICATED", false);
      commit("SET_AUTHENTICATING", false);
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      resolve(true);
    });
  },
  forgotPassword: async function ({ commit }, payload) {
    commit("SENDING_RESET_EMAIL", true);
    try {
      const response = await this.$http.post("/reset-password", payload);
      commit("SENDING_RESET_EMAIL", false);
      if (response.status == 200) {
        return true;
      }
    } catch (err) {
      commit("SENDING_RESET_EMAIL", false);
      throw err;
    }
  },
  resetPassword: async function ({ commit }, payload) {
    commit("RESETTING_PASSWORD", true);
    try {
      const response = await this.$http.post("/reset/password/", payload);
      commit("RESETTING_PASSWORD", false);
      if (response.status == 200) {
        return true;
      }
    } catch (err) {
      commit("RESETTING_PASSWORD", false);
      throw err;
    }
  },

  secondStepVerification: function ({ commit }, verificationData) {
    commit("SET_AUTHENTICATING", true);
    let vm = this;
    return new Promise(function (resolve, reject) {
      vm.$http
        .post(`verifyMFAToken`, verificationData)
        .then((tokenRes) => {
          if (tokenRes.status == 200) {
            if (tokenRes.data.success) {
              // localStorage.setItem('ARSS_TOKEN', tokenRes.data.access_token);
              // localStorage.setItem('ARSS_RF', tokenRes.data.refresh_token);
              commit("SET_AUTHENTICATED", true);
              commit("SET_SECOND_VERIFICATION_ENABLED", false); //TODO change it to be taken from API response
              //commit('SET_TOKEN', tokenRes.data.access_token);
              vm.$http.get("user").then((res) => {
                if (res.status == 200) {
                  const user = res.data.data;
                  let userType = "Patient";
                  if (user.isAdmin) {
                    userType = "Admin";
                  } else if (user.isProvider) {
                    userType = "Provider";
                  } else if (user.isManagingAdmin) {
                    userType = "Manager";
                  }
                  localStorage.setItem("USER_TYPE", userType);
                }
              });
              resolve(true);
            }
          }
          commit("SET_AUTHENTICATING", false);
          reject(tokenRes.data);
        })
        .catch((err) => {
          commit("SET_AUTHENTICATING", false);
          reject(err);
        });
    });
  },
  getOnlineUsers: function ({ commit }) {
    let vm = this;
    return new Promise((resolve) => {
      return vm.$http.get(`users/online`).then(
        (res) => {
          commit("SET_ONLINE_USERS", res.data.online_users);
          resolve(res);
        },
        () => {
          resolve(false);
        }
      );
    });
  },
  setOnlineUsers: function ({ commit }, users) {
    commit("SET_ONLINE_USERS", users);
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
