import dayjs from "dayjs";
import Swal from "sweetalert2";
import { sortBy, uniqBy } from "lodash";
export default {
  namespaced: true,
  state: {
    conversations: {
      loading: false,
      pagination: {
        total: 0,
        page: 0,
        itemsPerPage: 0,
        lastPage: null,
        data: [],
      },
    },
    conversationMessages: {
      loading: false,
      pagination: {
        nextCursor: null,
        limit: null,
        data: [],
      },
    },
    unreadCount: 0,
    createConversationLoading: false,
    addConversationParticipantLoading: null,
    removeConversationParticipantLoading: null,
    createConversationMessageLoading: false,
    updateConversationLoading: false,
    updateConversationAvatarLoading: false,
    selectedConversation: null,
    isExpanded: false,
    isFullscreen: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_CONVERSATIONS_LOADING: (state, data) => {
      state.conversations.loading = data;
    },
    SET_CONVERSATIONS_PAGINATION: (state, data) => {
      state.conversations.pagination = data;
    },
    SET_CONVERSATION_MESSAGES_LOADING: (state, data) => {
      state.conversationMessages.loading = data;
    },
    SET_CONVERSATION_MESSAGES_PAGINATION: (state, data) => {
      state.conversationMessages.pagination = data;
    },
    SET_SELECTED_CONVERSATION: (state, data) => {
      state.selectedConversation = data;
    },
    RESET_CONVERSATION_MESSAGES: (state) => {
      state.conversationMessages = {
        loading: false,
        pagination: {
          nextCursor: null,
          limit: null,
          data: [],
        },
      };
    },
    STORE_CONVERSATION_MESSAGE: (state, data) => {
      const messageIndex = state.conversationMessages.pagination.data.findIndex(
        (message) => message.id == data.id
      );
      if (messageIndex == -1) {
        data.read_at = dayjs();
        state.conversationMessages.pagination.data = [
          ...state.conversationMessages.pagination.data,
          data,
        ];
      }
    },
    SET_LATEST_CONVERSATION_MESSAGE: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.conversation_id
      );
      if (conversationIndex > -1) {
        state.conversations.pagination.data[conversationIndex].latest_message =
          { ...data };
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      }
    },
    UPDATE_CONVERSATION_ATTRIBUTES: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.id
      );
      if (conversationIndex > -1) {
        state.unreadCount = Math.max(
          state.unreadCount -
            state.conversations.pagination.data[conversationIndex].unread_count,
          0
        );

        state.conversations.pagination.data[conversationIndex] = {
          ...state.conversations.pagination.data[conversationIndex],
          ...data.data,
        };
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      }
    },
    UPDATE_CONVERSATION_LATEST_ATTRIBUTES: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.id
      );

      // if conversation has latest message and the conversation found in the list
      if (
        conversationIndex > -1 &&
        state.conversations.pagination.data[conversationIndex].latest_message
      ) {
        state.conversations.pagination.data[conversationIndex].latest_message =
          {
            ...state.conversations.pagination.data[conversationIndex]
              .latest_message,
            ...data.data,
          };
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      }
    },
    UPDATE_OR_CREATE_CONVERSATION: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.conversation.id
      );

      if (conversationIndex > -1) {
        const conversation =
          state.conversations.pagination.data[conversationIndex];

        //prevent flashing in conversation avatar if it is not changing
        let oldAvatar = conversation.avatar
          ? String(conversation.avatar).split("?")[0]
          : null;
        let newAvatar = data.conversation.avatar
          ? data.conversation.avatar.split("?")[0]
          : null;
        if (oldAvatar == newAvatar) {
          data.conversation.avatar = conversation.avatar;
        }

        /**
         * if no conversation selected(opened) OR the selected(opened) conversation
         * is not the same as the received conversation OR the conversation list is
         * not expanded and selected then increment the previous unread_count by 1
         * and set the read_at for the latest_message to null
         */
        if (
          (!state.selectedConversation ||
            state.selectedConversation.roomId != data.conversation.id ||
            (state.selectedConversation && !state.isExpanded)) &&
          !data.withoutIncreaseUnreadCount
        ) {
          if (data.conversation.latest_message) {
            data.conversation.latest_message.read_at = null;
          }
          data.conversation.unread_count =
            state.conversations.pagination.data[conversationIndex]
              .unread_count + 1;
          state.unreadCount++;
        }

        // update conversation
        state.conversations.pagination.data[conversationIndex] = {
          ...state.conversations.pagination.data[conversationIndex],
          ...data.conversation,
        };
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      } else {
        if (!data.withoutIncreaseUnreadCount) {
          if (data.conversation.latest_message) {
            data.conversation.latest_message.read_at = null;
          }
          data.conversation.unread_count++;
          state.unreadCount++;
        }

        // add new conversation
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
          { ...data.conversation },
        ];
      }
    },
    REMOVE_CONVERSATION: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.conversationId
      );
      if (conversationIndex > -1) {
        const deletedConversation = state.conversations.pagination.data.splice(
          conversationIndex,
          1
        );
        state.unreadCount = Math.max(
          state.unreadCount - deletedConversation[0].unread_count,
          0
        );
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      }
    },
    MARK_CONVERSATION_AS_READ: (state, data) => {
      const conversationIndex = state.conversations.pagination.data.findIndex(
        (conversation) => conversation.id == data.conversationId
      );
      if (conversationIndex > -1) {
        state.unreadCount = Math.max(
          state.unreadCount -
            state.conversations.pagination.data[conversationIndex].unread_count,
          0
        );

        state.conversations.pagination.data[conversationIndex].unread_count = 0;
        if (
          state.conversations.pagination.data[conversationIndex].latest_message
        ) {
          state.conversations.pagination.data[
            conversationIndex
          ].latest_message.read_at = dayjs();
        }
        state.conversations.pagination.data = [
          ...state.conversations.pagination.data,
        ];
      }
    },
  },
  actions: {
    getConversations: function ({ commit, state }, payload) {
      commit("SET_CONVERSATIONS_LOADING", true);
      let url = `conversations?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.conversationName) {
          url = `${url}&conversation_name=${payload.conversationName}`;
        }
      }
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            if (payload.limit) {
              if (payload.page == 1) {
                commit("SET_CONVERSATIONS_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_CONVERSATIONS_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: uniqBy(
                    [...state.conversations.pagination.data, ...res.data.data],
                    "id"
                  ),
                });
              }
            } else {
              commit("SET_CONVERSATIONS_PAGINATION", {
                total: 0,
                page: 0,
                itemsPerPage: 0,
                lastPage: null,
                data: res.data.data,
              });
            }
            commit("SET_CONVERSATIONS_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CONVERSATIONS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getConversation: function ({ commit }, payload) {
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`conversations/${payload.conversationId}`).then(
          (res) => {
            commit("UPDATE_OR_CREATE_CONVERSATION", {
              conversation: res.data.data,
            });
            resolve(res);
          },
          (err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createConversation: function ({ commit }, payload) {
      commit("SET_STATE", { key: "createConversationLoading", value: true });
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.post("conversations", payload).then(
          (res) => {
            commit("SET_STATE", {
              key: "createConversationLoading",
              value: false,
            });
            resolve(res);
          },
          (err) => {
            commit("SET_STATE", {
              key: "createConversationLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    updateConversation: function ({ commit }, payload) {
      commit("SET_STATE", { key: "updateConversationLoading", value: true });
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .put(`conversations/${payload.conversationId}`, payload.data)
          .then(
            (res) => {
              commit("UPDATE_OR_CREATE_CONVERSATION", {
                conversation: res.data.data,
              });
              commit("SET_STATE", {
                key: "updateConversationLoading",
                value: false,
              });
              resolve(res);
            },
            (err) => {
              commit("SET_STATE", {
                key: "updateConversationLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    updateConversationAvatar: function ({ commit }, payload) {
      commit("SET_STATE", {
        key: "updateConversationAvatarLoading",
        value: true,
      });
      let vm = this;
      const formData = new FormData();
      formData.append("avatar", payload.data.avatar);
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `conversations/${payload.conversationId}/update-avatar`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(
            (res) => {
              commit("UPDATE_OR_CREATE_CONVERSATION", {
                conversation: res.data.data,
              });
              commit("SET_STATE", {
                key: "updateConversationAvatarLoading",
                value: false,
              });
              resolve(res);
            },
            (err) => {
              commit("SET_STATE", {
                key: "updateConversationAvatarLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    addConversationParticipant: function ({ commit }, payload) {
      commit("SET_STATE", {
        key: "addConversationParticipantLoading",
        value: payload.data.participant_id,
      });
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `conversations/${payload.conversationId}/participants`,
            payload.data
          )
          .then(
            (res) => {
              commit("UPDATE_OR_CREATE_CONVERSATION", {
                conversation: res.data.data,
                withoutIncreaseUnreadCount: true,
              });
              commit("SET_STATE", {
                key: "addConversationParticipantLoading",
                value: false,
              });
              resolve(res);
            },
            (err) => {
              commit("SET_STATE", {
                key: "addConversationParticipantLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    removeConversationParticipant: function ({ commit }, payload) {
      commit("SET_STATE", {
        key: "removeConversationParticipantLoading",
        value: payload.participantId,
      });
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .delete(
            `conversations/${payload.conversationId}/participants/${payload.participantId}`
          )
          .then(
            (res) => {
              if (!payload.leave) {
                commit("UPDATE_OR_CREATE_CONVERSATION", {
                  conversation: res.data.data,
                  withoutIncreaseUnreadCount: true,
                });
              }
              commit("SET_STATE", {
                key: "removeConversationParticipantLoading",
                value: false,
              });
              resolve(res);
            },
            (err) => {
              commit("SET_STATE", {
                key: "removeConversationParticipantLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    sendConversationMessage: function ({ commit }, payload) {
      commit("SET_STATE", {
        key: "createConversationMessageLoading",
        value: true,
      });
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `conversations/${payload.conversationId}/messages`,
            payload.data
          )
          .then(
            (res) => {
              commit("SET_STATE", {
                key: "createConversationMessageLoading",
                value: false,
              });
              resolve(res);
            },
            (err) => {
              commit("SET_STATE", {
                key: "createConversationMessageLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getConversationMessages: function ({ commit, state }, payload) {
      commit("SET_CONVERSATION_MESSAGES_LOADING", true);
      let url = `conversations/${payload.conversationId}/messages?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.cursor) {
          url = `${url}&cursor=${payload.cursor}`;
        }
      }
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            if (payload.limit) {
              if (payload.cursor) {
                commit("SET_CONVERSATION_MESSAGES_PAGINATION", {
                  nextCursor: res.data.meta.next_cursor,
                  limit: res.data.meta.per_page,
                  data: [
                    ...sortBy([...res.data.data], ["created_at"]),
                    ...state.conversationMessages.pagination.data,
                  ],
                });
              } else {
                commit("SET_CONVERSATION_MESSAGES_PAGINATION", {
                  nextCursor: res.data.meta.next_cursor,
                  limit: res.data.meta.per_page,
                  data: sortBy(res.data.data, ["created_at"]),
                });
              }
            } else {
              commit("SET_CONVERSATION_MESSAGES_PAGINATION", {
                nextCursor: null,
                limit: null,
                data: res.data.data,
              });
            }
            // reset conversation read count locally
            commit("UPDATE_CONVERSATION_ATTRIBUTES", {
              id: payload.conversationId,
              data: {
                unread_count: 0,
              },
            });
            // update conversation latest message read_at locally
            commit("UPDATE_CONVERSATION_LATEST_ATTRIBUTES", {
              id: payload.conversationId,
              data: {
                read_at: dayjs(),
              },
            });
            commit("SET_CONVERSATION_MESSAGES_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CONVERSATION_MESSAGES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getConversationMessage: function ({ commit }, payload) {
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(
            `conversations/${payload.conversationId}/messages/${payload.messageId}`
          )
          .then(
            (res) => {
              commit("STORE_CONVERSATION_MESSAGE", res.data.data);
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    markMessageAsRead: function (context, payload) {
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `conversations/${payload.conversationId}/messages/${payload.messageId}/mark-as-read`
          )
          .then(
            (res) => {
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    toggleConversationMuteStatus: function ({ commit }, payload) {
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `conversations/${payload.conversationId}/participants/${payload.participantId}/toggle-mute`
          )
          .then(
            (res) => {
              commit("UPDATE_OR_CREATE_CONVERSATION", {
                conversation: res.data.data,
                withoutIncreaseUnreadCount: true,
              });
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getUnreadMessagesCount: function ({ commit }) {
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`conversations/unread-count`).then(
          (res) => {
            commit("SET_STATE", {
              key: "unreadCount",
              value: res.data.unread_count,
            });
            resolve(res);
          },
          () => {
            resolve(false);
          }
        );
      });
    },
    setSelectedConversation: function ({ commit }, payload) {
      commit("SET_SELECTED_CONVERSATION", payload);
    },
    resetConversationMessages: function ({ commit }) {
      commit("RESET_CONVERSATION_MESSAGES");
    },
    storeConversationMessage: function ({ commit }, payload) {
      commit("STORE_CONVERSATION_MESSAGE", payload);
    },
    setLatestConversationMessage: function ({ commit }, payload) {
      commit("SET_LATEST_CONVERSATION_MESSAGE", payload);
    },
    updateOrCreateConversation: function ({ commit }, payload) {
      commit("UPDATE_OR_CREATE_CONVERSATION", {
        conversation: payload,
      });
    },
    removeConversation: function ({ commit }, payload) {
      commit("REMOVE_CONVERSATION", payload);
    },
    setConversationListExpanded: function ({ commit }, value) {
      commit("SET_STATE", {
        key: "isExpanded",
        value,
      });
    },
    setConversationListFullscreen: function ({ commit }, value) {
      commit("SET_STATE", {
        key: "isFullscreen",
        value,
      });
    },
    markConversationAsRead: function ({ commit }, payload) {
      commit("MARK_CONVERSATION_AS_READ", payload);
    },
  },
};
