/* eslint-disable no-unused-vars */
import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    OCF21B: {
      loading: false,
      data: [],
    },
    OCF21BSentInvoices: {
      loading: false,
      data: [],
    },
    approvedInvoicesLoading: false,
  },
  mutations: {
    SET_OCF21B_DATA: (state, data) => {
      state.OCF21B.data = data;
    },
    SET_OCF21B_LOADING: (state, data) => {
      state.OCF21B.loading = data;
    },
    SET_OCF21B_SENT_INVOICES_DATA: (state, data) => {
      state.OCF21BSentInvoices.data = data;
    },
    SET_OCF21B_SENT_INVOICES_LOADING: (state, data) => {
      state.OCF21BSentInvoices.loading = data;
    },
    SET_APPROVED_INVOICES_LOADING: (state, data) => {
      state.approvedInvoicesLoading = data;
    },
  },
  actions: {
    getOCF21BInvoices: function ({ commit }) {
      commit("SET_OCF21B_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(`hcai/ocf21b`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_OCF21B_DATA", res.data.data);
            }
            commit("SET_OCF21B_LOADING", false);
            resolve(res.data.data);
          })
          .catch((err) => {
            commit("SET_OCF21B_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    getOCF21BSentInvoices: function ({ commit }) {
      commit("SET_OCF21B_SENT_INVOICES_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(`hcai/ocf21b-sent-invoices`)
          .then((res) => {
            if (res.status == 200) {
              commit("SET_OCF21B_SENT_INVOICES_DATA", res.data.data);
            }
            commit("SET_OCF21B_SENT_INVOICES_LOADING", false);
            resolve(res.data.data);
          })
          .catch((err) => {
            commit("SET_OCF21B_SENT_INVOICES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    approveOCF21BInvoices: function ({ commit }, payload) {
      commit("SET_APPROVE_INVOICES_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`hcai/approve-ocf21b-invoice`, payload)
          .then((res) => {
            if (res.status == 200) {
              Swal.fire({
                title: "Approved successfully",
                text: "Selected clients approved successfully",
                icon: "success",
              });
            }
            commit("SET_APPROVE_INVOICES_LOADING", false);
            resolve(true);
          })
          .catch((err) => {
            commit("SET_APPROVE_INVOICES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    approveAllOCF21B: function ({ state, commit }, status) {
      const newOCF21B = state.OCF21B.data.map((invoice) => {
        invoice.approved = status;
        return invoice;
      });
      commit("SET_OCF21B_DATA", newOCF21B);
    },
  },
};
