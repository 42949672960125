import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    data: null,
    isLoading: false,
    isSaving: false,
    usersAvailability: {
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_USERS_AVAILABILITY_LOADING: (state, data) => {
      state.usersAvailability.loading = data;
    },
    SET_PAGINATION: (state, data) => {
      state.usersAvailability.pagination = data;
    },
  },
  actions: {
    getUsersAvailability: function ({ commit, state }, payload) {
      let url = `users-availability-list?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.roleId) {
          url = `${url}&roleId=${payload.roleId}`;
        }
        if (payload.username) {
          url = `${url}&username=${payload.username}`;
        }
        if (payload.classIds) {
          url = `${url}&classIds=${payload.classIds}`;
        }
        if (payload.status) {
          url = `${url}&status=${payload.status}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_USERS_AVAILABILITY_LOADING", true);
      }
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              const usersData = res.data.data;

              usersData.forEach((userData) => {
                userData.customDates = userData.availability
                  .filter((item) => item.type == "date")
                  .map((object) => {
                    return {
                      dateValue: object.type_data,
                      intervals: object.intervals,
                    };
                  });
                userData.weekDays = {};
                userData.availability
                  .filter((item) => item.type == "wday")
                  .forEach((item) => {
                    userData.weekDays[item.type_data] = item.intervals || [];
                  });
              });

              if (payload && payload.page) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [
                    ...state.usersAvailability.pagination.data,
                    ...usersData,
                  ],
                });
              } else if (payload && payload.limit) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: usersData,
                });
              } else {
                commit("SET_PAGINATION", {
                  total: 0,
                  current: 0,
                  data: usersData,
                });
              }
              resolve(usersData);
            }
            commit("SET_USERS_AVAILABILITY_LOADING", false);
          })
          .catch((err) => {
            commit("SET_USERS_AVAILABILITY_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  (err.data || err.response.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            return resolve();
          });
      });
    },
    getUserAvailability: function (context, data = {}) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`users/${data.user_id}/availability`)
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "data",
                value: res.data.data,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            context.commit("SET_STATE", {
              key: "data",
              value: null,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve([]);
            }
          });
      });
    },
    updateUserAvailability: function (context, data = {}) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`users/${data.user_id}/availability`, {
            data: data.availability,
          })
          .then((res) => {
            if (res) {
              if (!data.noSuccessMessage) {
                Swal.fire({
                  title: "Availability Updated",
                  text: res.data.message,
                  icon: "success",
                });
              }
              context.commit("SET_STATE", { key: "isSaving", value: false });
            }
            resolve(res);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Server Error",
                text:
                  ((err.data || {}).error || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
  },
};
