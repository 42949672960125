import Swal from "sweetalert2";
import router from "../router/index";
import professions from "./professions";

export default {
  namespaced: true,
  state: {
    data: [],
    user: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },

  actions: {
    update: function (context, data) {
      var requestData = {
        first: data.first,
        last: data.last,
        email: data.email,
        bio: data.bio,
        nickname: data.nickname,
        enableSecondStepVerification: data.enableSecondStepVerification,
        enable_sending_sms: data.enable_sending_sms,
        enable_sending_email: data.enable_sending_email,
        phone_number: data.phone_number,
        birth_date: data.birth_date || null,
        hstNumber: data.hstNumber,
        gender: data.gender,
      };

      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put("users" + "/" + data.id, requestData)
          .then((res) => {
            if (res) {
              if (!data.noSuccessMessage) {
                Swal.fire({
                  title: "Profile Updated",
                  text: "Profile has been updated successfully",
                  icon: "success",
                });
              }
              context.commit("SET_STATE", { key: "isSaving", value: false });
            }
            resolve(res);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    updatePhoto: function (context, data) {
      let vm = this;
      return new Promise(function (resolve) {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        let formData = new FormData();
        formData.append("profile_picture", data.profile_picture);
        vm.$http
          .post(`users/${context.state.user.id}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res) {
              Swal.fire({
                title: "Image Updated",
                text: "Image has been updated successfully",
                icon: "success",
              });
              context.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    get: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get("user")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "user",
                value: res.data.data,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            resolve();
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    changePassword: function (context, resetData) {
      let vm = this;
      return new Promise(function (resolve) {
        let dataToSend = {
          token: localStorage.getItem("ARSS_TOKEN"),
          old_password: resetData.currentPassword,
          password: resetData.newPassword,
          password_confirmation: resetData.confirmPassword,
        };
        if (resetData.userId) {
          dataToSend.user_id = resetData.userId;
        }
        context.commit("SET_STATE", { key: "isSaving", value: true });
        vm.$http
          .put("user/password/", dataToSend)
          .then((res) => {
            if (res) {
              Swal.fire({
                title: "Password Updated",
                text: "Password has been updated successfully",
                icon: "success",
              });
              context.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    createAddress: function (context, data) {
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      if (data) {
        this.$http
          .post(`users/${data.user_id}/address`, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });

              context.dispatch("get").then(() => {
                router.push({
                  name: "admin.profile.address",
                });
              });
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire(
                "Error",
                (((err || {}).data || {}).error || {}).message || err,
                "error"
              );
            }
          });
      }
    },
    removeAddress: function (context, payload) {
      this.$http
        .delete(`users/${payload.user}/address/${payload.id}`)
        .then((res) => {
          if (res.status == 200) {
            Swal.fire({
              title: "Deleted",
              text: "Address Deleted",
              icon: "success",
            });
            context.dispatch("get");
          }
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire(
              "Error",
              (((err || {}).data || {}).error || {}).message || err,
              "error"
            );
          }
        });
    },
    deleteUser: function (_, id) {
      this.$http
        .delete(`users/${id}`)
        .then((res) => {
          if (res.status == 200) {
            Swal.fire({
              title: "Deleted",
              text: "User Deleted",
              icon: "success",
            });
          }
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire(
              "Error",
              (((err || {}).data || {}).error || {}).message || err,
              "error"
            );
          }
        });
    },
    createPhone: function (context, data) {
      if (!data.number || !data.number.trim().length) {
        return Swal.fire("Error", "Phone number is required", "error");
      }
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      if (data) {
        this.$http
          .post(`users/${data.user_id}/phones`, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });

              context.dispatch("get").then(() => {
                router.push({
                  name: "admin.profile.phones",
                });
              });
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire(
                "Error",
                (((err || {}).data || {}).error || {}).message || err,
                "error"
              );
            }
          });
      }
    },
    removePhone: function (context, payload) {
      this.$http
        .delete(`users/${payload.user}/phones/${payload.id}`)
        .then((res) => {
          if (res.status == 200) {
            Swal.fire({
              title: "Deleted",
              text: "Phone Deleted",
              icon: "success",
            });
            context.dispatch("get");
          }
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire(
              "Error",
              (((err || {}).data || {}).error || {}).message || err,
              "error"
            );
          }
        });
    },
  },
  modules: {
    professions,
  },
};
